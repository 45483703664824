<template>
    <div>
        <section class="developers-home">
            <div class="container">
                <h1 class="developers-title">Explore Our Available Developers</h1>
            </div>
        </section>
        <div class="container row contacts-content">
            <div class="column">
                <p>Unlike platforms that merely provide developers, we're dedicated to delivering value and results in our collaborations. As a result-oriented company, our focus isn't solely on supplying a developer but on enhancing or building your product with exceptional quality, within reasonable terms and pricing.</p>
                <p>However, we understand that some clients already have established IT structures and simply require a skilled developer to swiftly expand their team.</p>
                <p>Why not connect such clients directly with the right developer from our bench?</p>
                <p>With multiple projects in our pipeline, we consistently have developers ready and waiting for assignments.</p>
                <p>Browse our available developers and select the one you believe is suitable for your needs.</p>
                <p>Since we are not operating as a typical developer's marketplace, the information about our available developers is not publicly accessible. Please introduce yourself, and we'll provide you with access to our roster of talented developers.</p>
            </div>
            <div class="column contacts-form developers-home-container">
                <AvailableDevForm @onFormSubmission="onFormSubmission">
                    <div class="contacts-contact-form-header" v-if="submission.isSent" :class="submission.isSent ? 'modal' : ''">
                        <button class="modal-close" @click="close()" aria-label="Close">
                            <img v-svg-inline :data-src="require('@/assets/icons/close-modal.svg')" alt="" />
                        </button>
                        <div class="contacts-contact-form-description" :class="{
                            error: submission.hasError,
                            success: !submission.hasError
                        }">
                            {{ submission.messageText }}
                        </div>
                    </div>
                </AvailableDevForm>
            </div>
        </div>
    </div>

</template>
<script>
import AvailableDevForm from "../components/Form/AvailableDevForm.vue";

export default {
    data() {
        return {
            submission: {}
        }
    },
    components: {
        AvailableDevForm,
    },
    methods: {
        close() {
            this.submission.isSent = false
        },
        getDefaultSubmission() {
            return {
                isSent: false,
                hasError: false,
                messageText: ''
            }
        },
        onFormSubmission(payload) {
            Object.assign(this.submission, this.getDefaultSubmission(), payload);
        }
    }
}
</script>

<style lang="scss">
@import "../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.developers-home {
    padding: 60px 0;

    @include media('>=desktop') {
        padding: 120px 0;
    }

    @include media('>=widescreen') {
        padding: 240px 0 120px;
    }
}

.developers-title {
    text-transform: uppercase;
    color: $black;
    line-height: 111%;
    margin: 0;
    font-size: 34px;

    @include media('>=tablet') {
        font-size: 50px;
    }

    @include media('>=tablet-xl') {
        font-size: 60px;
    }

    @include media('>=desktop') {
        font-size: 72px;
    }
}

.developers-home-container {
    a {
        color: $red;
    }

    p {
        margin-bottom: 32px;
    }

    .button {
        margin-top: 8px;
    }

    .form {
        max-height: 100%;
    }

    .input-group {
        .placeholder {
            right: 11px;
        }

        textarea {
            height: 158px;

            @include media("<phone") {
                height: 180px;
            }

            @include media(">=tablet") {
                height: 132px;
            }

            @include media(">=widescreen") {
                height: 132px;
            }
        }
    }

}
</style>
