<template>
  <div class="contact-form">
    <form class="form" @submit.prevent="submitForm">
      <div class="input-group">
        <input
          name="name"
          id="name"
          v-model.trim="name"
          type="text"
          autocomplete="on"
          placeholder=" "
          class="input-group__input"
          @change="v$.name.$validate()"
          :class="[{ error: v$.name.$error }, { success: name.length }]"
        />
        <label class="placeholder" for="name" :class="{ error: v$.name.$error }"
          >Your Name<span>*</span></label
        >
      </div>
      <span class="error" v-if="v$.name.$error"> Error: name is required </span>
      <div class="input-group">
        <input
          name="email"
          id="email"
          v-model.trim="email"
          placeholder=" "
          type="email"
          autocomplete="on"
          class="input-group__input"
          @change="v$.email.$validate()"
          :class="[{ error: v$.email.$error }, { success: email.length }]"
        />
        <label class="placeholder" for="email" :class="{ error: v$.email.$error }"
          >Email Address<span>*</span></label
        >
      </div>
      <span class="error" v-if="v$.email.$error">
        Error: email entered incorrectly
      </span>
      <div class="input-group">
        <input
          name="url"
          id="url"
          v-model.trim="url"
          type="text"
          autocomplete="on"
          placeholder=" "
          class="input-group__input"
          @change="v$.url.$validate()"
          :class="[{ error: v$.url.$error }, { success: url.length }]"
        />
        <label class="placeholder" for="url" :class="{ error: v$.url.$error }"
          >Your Company URL<span>*</span></label
        >
      </div>
      <span class="error" v-if="v$.url.$error"> Error: URL is required </span>
      <div class="input-group input-group__textarea">
        <textarea
          name="message"
          id="message"
          placeholder=" "
          v-model.trim="message"
          @change="v$.message.$validate()"
          :class="[{ error: v$.message.$error }, { success: message.length }]"
        ></textarea>
        <label class="placeholder" for="message" :class="{ error: v$.message.$error }"
          >Please describe the type of developer you are looking for and the approximate duration of the project. Alternatively, provide a brief description of why you need access in two words.<span>*</span></label
        >
      </div>
      <span class="error" v-if="v$.message.$error">
        Error: message is required
      </span>
      <div class="input-group__captcha">
        <label>Security Code:<span>*</span></label>
        <button
          type="button"
          class="captcha__refresh"
          aria-label="refresh captcha button"
          @click="getValueForCaptcha()"
        >
          <img 
            v-svg-inline
           :data-src="require(`../../assets/icons/captcha.svg`)"/>
        </button>
        <canvas id="canvas" width="72" height="38" />
        <div class="captcha">
          <div class="input-group">
            <input
              name="captcha"
              id="captcha"
              v-model.trim="inputValueForCaptcha"
              type="text"
              placeholder=" "
              class="input-group__input"
              @change="v$.inputValueForCaptcha.$validate()"
              :class="[
                { error: v$.inputValueForCaptcha.$error },
                { success: inputValueForCaptcha.length },
              ]"
            />
            <label
              class="placeholder"
              :class="{ error: v$.inputValueForCaptcha.$error }"
              for="captcha"
              >Code</label
            >
          </div>
        </div>
      </div>
      <span class="captcha error" v-if="v$.inputValueForCaptcha.$error">
        Security code is incorrect
      </span>
       <button class="button primary large send" type="submit" :class="[isFormSubmissionInProgress?'loading':'']" :disabled="isFormSubmissionInProgress">
        {{isFormSubmissionInProgress?'Sending':'Request Access'}}
        <span v-if="!isFormSubmissionInProgress" class="icon"
          ><img
            v-svg-inline
            :data-src="require('../../assets/icons/rarr-icon.svg')"
            alt=""
        /></span>
      </button>
    </form>
  <slot></slot>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import { required, email, sameAs } from "@vuelidate/validators";
import axios from "axios";

export default {
  name: "FormContact",
  data() {
    return {
      v$: useValidate(),
      name: "",
      email: "",
      url: "",
      message: "",
      valueForCaptcha: "",
      inputValueForCaptcha: "",
      isFormSubmissionInProgress: false,
    };
  },
  props:['description'],
  validations() {
    return {
      name: { required },
      email: { required, email },
      url: { required },
      message: { required },
      inputValueForCaptcha: {
        required,
        sameAs: sameAs(this.valueForCaptcha),
      },
    };
  },
  methods: {
    submitForm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const messageFromUser = {
          subject:'Available Developers request',
          text:this.description,
          url:this.url,
          name: this.name,
          email: this.email,
          message: this.message,
          files: this.files,
        };
        this.isFormSubmissionInProgress = true;
        axios.post("/backend/contact.php", messageFromUser, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((resp) => {
          // handle success
          const isSuccess = resp?.data?.success || false;
          this.url = '';this.name = '';this.email='';this.message='';
          this.v$.$reset();
          

          this.$emit("onFormSubmission", {
            isSent: true,
            hasError: !isSuccess,
            // messageText: resp?.data?.message || 'Thank you for providing the information. We\'ll grant you access to the catalog of available developers shortly.'
            messageText: 'Thank you for providing the information. We\'ll grant you access to the catalog of available developers shortly.'
          });
        })
        .catch((err) => {
          // handle error
          this.$emit("onFormSubmission", {
              isSent: true,
              hasError: true,
              messageText: err?.response?.messageText || 'An internal error has occurred. Please try again later.'
          });
        })
        .then(() => {
          // always executed
          this.isFormSubmissionInProgress = false;
        });
      }
      return;
    },
    getValueForCaptcha() {
      let chars = "abcdefghijklmnopqrstuvwxyz0123456789";
      let code = [];
      for (let i = 0; i < 4; i++) {
        let sIndex = Math.floor(Math.random() * chars.length);
        let cTxt = chars[sIndex];
        code.push(cTxt);
      }
      let string = code.join("");
      this.valueForCaptcha = string;
      function drawPic() {
        const canvas = document.getElementById("canvas");
        const ctx = canvas.getContext("2d");
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = "#000000";
        ctx.font = 20 + "px SimHei";
        const x = 0.5 * (canvas.width / (string.length + 1));
        const y = 0.5 * canvas.height;
        const deg = Math.floor(Math.random() * (-15 - 15) + 15);
        ctx.translate(x, y);
        ctx.rotate((deg * Math.PI) / 180);
        ctx.fillText(string, 0, 0);
        ctx.rotate((-deg * Math.PI) / 180);
        ctx.translate(-x, -y);
      }
      drawPic();
    },
  },
  mounted() {
    this.getValueForCaptcha();
  },
};
</script>

<style lang="scss">
@import "./form-styles.scss";
.contact-form{
  position: relative;
}
.contacts-contact-form-header.modal{
  position:absolute;
  z-index:10;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  opacity:0.9;
  width:105%;
  height: 100%;
  .contacts-contact-form-description{
    text-align: left;
    border-radius:26px;
    color:$red;
    border:1px solid $red;
    z-index:50;
    background: $white;
    height:200px ;
    padding:50px 20px;
    line-height: 36px;
  }
}
</style>
